<template>
    <ToastNotification :message="message" :isVisible="isVisible" :bgColor="bgc" />
    <div v-if="isShown" class="w-screen h-screen bottom-0 top-0 left-0 right-0 fixed z-20 inset-0 flex justify-center items-center bg-black bg-opacity-25">
        <div class="fixed right-0 top-0 left-0 bottom-0 -z-10 w-screen h-screen"></div>
        <div class="w-1/2 p-4 h-fit max-h-5/6 rounded-md bg-white flex flex-col justify-start items-center overflow-hidden">
            <div class="w-full flex flex-row justify-between items-center py-4">
                <h1 class="text-xl font-medium w-full text-left text-slate-700 font-[600]">{{ $t("Create a custom question") }}</h1>
                <button class="text-slate-700 text-xl font-bold" @click="closePanel"><font-awesome-icon :icon="['fas', 'times']" /></button>
            </div>
            <hr class="h-[5px] bg-[#F5F8FF] w-full" />
            <!-- <select name="" id="" v-model="qstType" class="border border-slate-300 rounded-sm w-11/12 mx-auto my-6 h-10 px-3">
                <option value="" selected>Select a question type</option>
                <option value="Essay">Essay</option>
                <option value="Multiple-choice">Multiple choice</option>
            </select> -->
            <h1 class="text-lg mt-4 font-medium w-full text-left text-slate-700 font-[600]">{{ $t("Select Question Type") }}</h1>
            <div class="w-full flex justify-around my-4">
                <div
                    @click="qstType = 'Multiple-choice'"
                    class="w-1/3 h-34 flex flex-row items-center justify-between p-6 text-black shadow-md rounded-lg border transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg cursor-pointer group"
                    :class="{ 'bg-[#00AEF0] text-white border-[#00AEF0]': qstType === 'Multiple-choice' }"
                >
                    <!-- SVG Icon with Dynamic Color -->
                    <svg width="50" height="50" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg" class="transition duration-300">
                        <path
                            d="M150 28.125V37.5H56.25V28.125H150ZM56.25 56.25H150V65.625H56.25V56.25ZM56.25 84.375H150V93.75H56.25V84.375ZM56.25 112.5H150V121.875H56.25V112.5ZM35.0098 72.5098L39.9902 77.4902L14.0625 103.418L1.02539 90.3809L6.00586 85.4004L14.0625 93.457L35.0098 72.5098ZM35.0098 16.2598L39.9902 21.2402L14.0625 47.168L1.02539 34.1309L6.00586 29.1504L14.0625 37.207L35.0098 16.2598Z"
                            class="transition duration-300"
                            :fill="qstType === 'Multiple-choice' ? 'white' : '#00AEF0'"
                        />
                    </svg>

                    <!-- Text -->
                    <p class="font-semibold text-xl transition duration-300">{{ $t("Multiple choice") }}</p>
                </div>
                <div
                    @click="qstType = 'Essay'"
                    class="w-1/3 h-34 flex flex-row items-center justify-between p-7 text-black shadow-md rounded-lg border transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg cursor-pointer group"
                    :class="{ 'bg-[#00AEF0] text-white border-[#00AEF0]': qstType === 'Essay' }"
                >
                    <!-- SVG Icon with Dynamic Color -->
                    <svg width="50" height="50" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ml-5">
                        <path
                            d="M100 17.1875H50C37.9188 17.1875 28.125 26.9813 28.125 39.0625V110.938C28.125 123.019 37.9188 132.812 50 132.812H100C112.081 132.812 121.875 123.019 121.875 110.938V39.0625C121.875 26.9813 112.081 17.1875 100 17.1875Z"
                            :stroke="qstType === 'Essay' ? 'white' : '#00AEF0'"
                            stroke-width="12"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="transition duration-300"
                        />
                        <path
                            d="M53.125 42.2188H96.875M53.125 67.2188H96.875M53.125 92.2188H75"
                            :stroke="qstType === 'Essay' ? 'white' : '#00AEF0'"
                            stroke-width="12"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="transition duration-300"
                        />
                    </svg>

                    <!-- Text -->
                    <p class="font-semibold text-xl transition duration-300 mr-5">Essay</p>
                </div>
            </div>
            <!-- <Transition name="slide"> -->
            <div v-show="qstType == 'Essay'" class="flex w-5/6 bg-white rounded flex-col items-center mx-auto overflow-hidden justify-center">
                <form @submit.prevent="addCustomQuestion()" class="flex flex-col gap-5 pb-4 items-center justify-center h-full w-full">
                    <div class="flex flex-row justify-between gap-4 items-center w-full">
                        <div class="flex flex-col items-start gap-0 justify-between w-4/5">
                            <label for="qstTitle">{{ $t("Title") }}</label>
                            <input
                                id="qstTitle"
                                required
                                v-model="essay.name"
                                class="w-full p-2 border border-solid border-gray-300 rounded shadow-md"
                                type="text"
                                placeholder="Enter question title..."
                            />
                        </div>
                        <div class="flex flex-col items-start gap-0 justify-between w-1/5">
                            <label for="qstTime">{{ $t("Time") }} (minutes)</label>
                            <input
                                id="qstTime"
                                required
                                v-model="essay.time"
                                class="w-full p-2 border border-solid border-gray-300 rounded shadow-md"
                                value="1"
                                min="1"
                                type="number"
                                placeholder="Enter essay time..."
                            />
                        </div>
                    </div>

                    <textarea
                        required
                        v-model="essay.question"
                        class="w-full h-36 resize-none p-2 border border-solid border-gray-300 rounded shadow-md"
                        placeholder="Enter essay question..."
                        name=""
                        id=""
                    ></textarea>
                    <hr class="h-[5px] bg-[#F5F8FF] w-full mt-4" />

                    <div class="flex flex-row items-center justify-end gap-4 w-full">
                        <button class="w-32 h-10 px-2 rounded bg-transparent font-semibold text-slate-700 shadow-md hover:shadow-none duration-300" @click="cancelPanel">{{ $t("Cancel") }}</button>
                        <button type="submit" class="w-32 h-10 px-2 rounded bg-NeonBlue font-semibold text-white shadow-md hover:shadow-none duration-300">{{ $t("Confirm") }}</button>
                    </div>
                </form>
            </div>
            <!-- </Transition>
            <Transition name="slide"> -->
            <div v-show="qstType == 'Multiple-choice'" class="flex bg-white w-5/6 rounded flex-col items-center gap-5 overflow-hidden justify-center">
                <form @submit.prevent="addCustomQuestion()" class="flex flex-col gap-5 pb-4 items-center justify-center h-full w-full">
                    <div class="flex flex-row justify-between gap-4 items-center w-full">
                        <div class="flex flex-col items-start gap-0 justify-between w-4/5">
                            <label for="qstTitle_">{{ $t("Title") }}</label>
                            <input
                                id="qstTitle_"
                                required
                                v-model="multiple_choice.name"
                                class="w-full p-2 border border-solid border-gray-300 rounded shadow-md"
                                type="text"
                                placeholder="Enter question title..."
                            />
                        </div>
                        <div class="flex flex-col items-start gap-0 justify-between w-1/5">
                            <label for="qstTime_">{{ $t("Time") }} (minutes)</label>
                            <input
                                id="qstTime_"
                                required
                                v-model="multiple_choice.time"
                                value="1"
                                min="1"
                                max="5"
                                class="w-full p-2 border border-solid border-gray-300 rounded shadow-md"
                                type="number"
                                placeholder="Enter Multiple-choice time..."
                            />
                        </div>
                    </div>
                    <textarea
                        required
                        v-model="multiple_choice.question"
                        class="w-full h-36 resize-none p-2 border border-solid border-gray-300 rounded shadow-md"
                        placeholder="Enter Multiple-choice question..."
                        name=""
                        id=""
                    ></textarea>
                    <div class="flex max-h-52 relative overflow-y-auto items-center flex-col gap-2 justify-center w-full">
                        <div v-for="(option, key) in multiple_choice.options" class="flex items-center gap-2 w-full" :key="key">
                            <input
                                :id="'option_' + key"
                                :placeholder="`Enter option n°${key}`"
                                v-model="multiple_choice.options[key]"
                                class="w-11/12 p-2 border border-solid border-gray-300 rounded shadow-md"
                                type="text"
                                required
                            />
                            <button
                                v-if="shouldRenderDeleteButton(key)"
                                class="flex justify-center items-center absolute right-7 bottom-0 -translate-y-1/2 border border-gray-300 rounded-full w-6 h-6 cursor-pointer hover:opacity-95"
                                @click="deleteOption(key)"
                            >
                                <font-awesome-icon class="bg-white font-normal w-3 h-3" :icon="['fas', 'minus']" />
                            </button>
                        </div>
                        <button class="absolute w-6 h-6 flex justify-center items-center rounded-full bottom-0 border border-gray-300 -translate-y-1/2 right-0" @click="addOption">
                            <font-awesome-icon class="w-3 h-3 font-light" :icon="['fas', 'plus']" />
                        </button>
                    </div>
                    <!-- <div class="w-full flex justify-center">
                            <button class="w-full bg-[#53b9ab] h-10 px-2 rounded font-semibold text-white shadow-md hover:opacity-95" @click="addOption">Add Option</button>
                        </div> -->
                    <hr class="h-[5px] bg-[#F5F8FF] w-full mt-4" />

                    <div class="flex flex-row items-center justify-end gap-4 w-full">
                        <button class="w-32 h-10 px-2 rounded bg-transparent font-semibold text-slate-700 shadow-md hover:shadow-none duration-300" @click="cancelPanel">{{ $t("Cancel") }}</button>
                        <button type="submit" class="w-32 h-10 px-2 rounded bg-NeonBlue font-semibold text-white shadow-md hover:shadow-none duration-300">{{ $t("Confirm") }}</button>
                    </div>
                </form>
            </div>
            <!-- </Transition> -->
        </div>
    </div>
</template>
<script>
import axios from "axios";
//import LoaderComponent from './LoaderComponent';
import ToastNotification from "./ToastNotification.vue";
import { useStore } from "../store/index";

export default {
    name: "AddCustomQuestion",
    components: { ToastNotification },
    props: {
        isShown: Array,
        closePanel: Function,
        addQst: Function,
        type: Array,
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    data() {
        return {
            qstType: "",
            essay: {
                name: "",
                question: "",
                category: "Essay",
                time: 1,
            },
            multiple_choice: {
                name: "",
                question: "",
                category: "Multiple-choice",
                time: 1,
                options: {
                    1: "", // Initially empty
                    2: "", // Initially empty
                },
            },
        };
    },
    methods: {
        cancelPanel() {
            this.qstType = "";
            this.essay = {
                name: "",
                question: "",
                category: "Essay",
                time: 1,
            };
            this.multiple_choice = {
                name: "",
                question: "",
                category: "Multiple-choice",
                time: 1,
                options: {
                    1: "",
                    2: "",
                },
            };
            this.closePanel();
        },
        addCustomQuestion() {
            let data = {};
            if (this.qstType == "Essay") {
                data = this.essay;
            } else if (this.qstType == "Multiple-choice") {
                data = this.multiple_choice;
            }
            axios
                .post("https://server.go-platform.com/CustomQuestion/add", data, { withCredentials: true })
                .then((response) => {
                    this.message = "Question created successfully!";
                    this.bgc = "success";
                    this.isVisible = true;
                    this.closePanel();
                    this.Store.allCustomQuestions = [];
                    this.Store.fetchCustomCompany();
                    this.Store.fetchCustomGoPlatform();
                    this.addQst(response.data.newQ);
                })
                .catch((error) => {
                    console.log("Error while creating question", error);
                    this.message = "Creating a question has failed";
                    this.bgc = "error";
                    this.isVisible = true;
                    this.closePanel();
                });
        },

        addOption() {
            const newKey = Object.keys(this.multiple_choice.options).length + 1;
            // Using normal assignment to add a new property
            this.multiple_choice.options[newKey.toString()] = "";
        },
        deleteOption(key) {
            // Using delete to remove the property
            delete this.multiple_choice.options[key];
        },
        shouldRenderDeleteButton(key) {
            const keys = Object.keys(this.multiple_choice.options);
            const lastKey = keys[keys.length - 1];
            return key !== "1" && key !== "2" && key == lastKey;
        },
    },
};
</script>
<style scoped lang="scss">
.slide-enter-active {
    transition: all 1s ease;
    transition-delay: 0.2s;
}

.slide-leave-active {
    transition: all 0.2s ease;
}

.slide-enter-from,
.slide-leave-to {
    // transform: translateX(-500px);
    opacity: 0;
}

.slide-enter-to,
.slife-leave-from {
    opacity: 1;
    // transform: translateX(0);
}
</style>
